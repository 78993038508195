<template>

  <div style="position: relative;">

    <div class="imagePosition nodrag noselect">
      <v-img v-on:load="loadedImage()" contain :src="leftImage" >
        <v-img :src="updateImageHandle.img.one" :style="updateImageHandle.status == true ? 'opacity: 1' : 'opacity: 0'" style="height: 100%; width: 100%"></v-img>
  
      </v-img>
    </div>

    <div class="imagePosition nodrag noselect"
         id="image2"
         :src="rightImage"
         :style="rayImageStyle">
      <v-img v-on:load="loadedImage()" contain :src="rightImage" style="position: absolute; top: 0px;">
        <v-img :src="updateImageHandle.img.two" :style="updateImageHandle.status == true ? 'opacity: 1' : 'opacity: 0'" style="height: 100%; width: 100%"></v-img>
      
      </v-img>

    </div>

    <vue-draggable-resizable id="draggableElement"
                             :key="refreshPosition"
                             class="noselect"
                             :lockAspectRatio="lockAspect"
                             class-name-handle="my-handle-class"
                             :min-width="200"
                             :min-height="200"
                             :x="moveableElement.x"
                             :y="moveableElement.y"
                             :w="moveableElement.width"
                             :h="moveableElement.height"
                             @dragging="onDrag"
                             @resizing="onResize"
                             :parent="false"
                             :resizable="true"
                             :prevent-deactivation="false"
                             style="border: 1px dashed white; padding-top: 165px; cursor: move;"
                             :onDragStart="onDragStartCallback">
    </vue-draggable-resizable>

  </div>

</template>

<script>

  export default {
    props: ['leftImage', 'rightImage', 'center', 'update'],
    data() {
      return {
       
        updateImageHandle: {
          status: false,
          img: {
            one: undefined,
            two: undefined
          }
        },
        lockAspect: false,
        moveableElement: {
          x: 200,
          y: 200,
          height: 200,
          width: 200
        },
        criticalServerError: false,
        rightClick: {
          show: false,
          x: 0,
          y: 0
        },
        opacityValue: 1,

        fullscreen: false,

        loadingMeta: true,

        lastPosition: 1,
        showContent: false,
        refreshPosition: false,
        relImageSize: null,
        forwardImage: {
          small: true,
          medium: true,
          big: true
        }
      }
    },

    watch: {
     
      center: function() {
        // // console.l("CENTER ELEMENT")
        this.centerElement(0, true)
      },
      update: function() {
        if (this.update == true) {
          this.updateImageHandle.status = true
          this.updateImageHandle.img.one = this.leftImage
          this.updateImageHandle.img.two = this.rightImage
        }
      }
    },

    mounted() {
      window.addEventListener('keydown', this.keyboardInputDown)
      window.addEventListener('keyup', this.keyboardInputUp)
      window.addEventListener('resize', this.windowResizeEvent)
      this.centerElement(256, false)
    },

    destroyed() {
      window.removeEventListener('resize', this.windowResizeEvent)
    },

    computed: {
      rayImageStyle() {
        return `clip: rect(${this.moveableElement.y}px, ${this.moveableElement.x + this.moveableElement.width}px, ${this
          .moveableElement.y + this.moveableElement.height}px, ${this.moveableElement.x}px);`
      }
    },

    methods: {
      loadedImage() {
        this.updateImageHandle.status = false
        this.updateImageHandle.img.one = this.leftImage
        this.updateImageHandle.img.two = this.rightImage

        this.$emit('rendered')
        // // console.l("LOADED IAMGES")
      },
      keyboardInputDown(event) {
        if (event.keyCode == 91) {
          this.lockAspect = true
        }
      },
      keyboardInputUp(event) {
        if (event.keyCode == 91) {
          this.lockAspect = false
        }
      },
      finishedLoading() {
        // // console.l("FINISHED LOADING!!!");
      },

      centerElement(diff, resize) {
        if (resize == true) {
          this.moveableElement.width = 200
          this.moveableElement.height = 200
        }

        try {
          var draggableElement = document.getElementById('relationContainer').getBoundingClientRect()

          this.moveableElement.x = (draggableElement.width - diff) / 2 - this.moveableElement.width / 2
          this.moveableElement.y = draggableElement.height / 2 - this.moveableElement.height / 2
        } catch {
          // console.l()
        }
      },
      show(e) {
        e.preventDefault()
        this.rightClick.show = false
        this.rightClick.x = e.clientX
        this.rightClick.y = e.clientY
        this.$nextTick(() => {
          this.rightClick.show = true
        })
      },

      windowResizeEvent() {
        //this.cropImage();
      },

      opacityChangeHandle(opacityValueNumb) {
        this.opacityValue = opacityValueNumb / 100
      },

      cropImage() {
        //   var element = document.getElementById("image2");
        //   var offsetTop = element.getBoundingClientRect().top;
        //   if (this.fullscreen) {
        //     element.style.clip = `rect(${this.y - parseInt(offsetTop)}px, ${
        //       this.width + this.x
        //     }px, ${this.height + this.y - parseInt(offsetTop)}px, ${this.x}px)`;
        //   } else {
        //     element.style.clip = `rect(${this.y - parseInt(offsetTop)}px, ${
        //       this.width + this.x
        //     }px, ${this.height + this.y - parseInt(offsetTop)}px, ${this.x}px)`;
        //   }
      },
      onResize: function(x, y, width, height) {
        this.moveableElement.x = x
        this.moveableElement.y = y
        this.moveableElement.width = width
        this.moveableElement.height = height
        // this.cropImage();
      },
      onDrag: function(x, y) {
        this.moveableElement.x = x
        this.moveableElement.y = y

        // this.cropImage();
      },
      onDragStartCallback() {
        // // console.l("DRAG START")
      }

      // fullscreenChange(fullscreen) {
      //   var element = document.getElementById("image2");
      //   var newSize = element.getBoundingClientRect();

      //   this.height = this.height * (newSize.height / this.relImageSize.height);
      //   this.width = this.width * (newSize.width / this.relImageSize.width);

      //   this.x = this.x * (newSize.width / this.relImageSize.width);
      //   // this.y = this.y * ((newSize.bottom)/(this.relImageSize.bottom))  //??????

      //   this.fullscreen = fullscreen;

      //   this.relImageSize = newSize;
      //   this.refreshPosition = !this.refreshPosition;
      //   this.cropImage();
      // },
    }
  }

</script>

<style >

  @keyframes rotating {
    0% {
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -ms-transform: scale(1.3);
      -moz-transform: scale(1.3);
      -webkit-transform: scale(1.3);
      -o-transform: scale(1.3);
      transform: scale(1.3);
    }
    100% {
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  .rotating {
    -webkit-animation: rotating 3s linear infinite;
    -moz-animation: rotating 3s linear infinite;
    -ms-animation: rotating 3s linear infinite;
    -o-animation: rotating 3s linear infinite;
    animation: rotating 3s linear infinite;
  }

  .imagePosition {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;

    background-size: contain;
  }
  .my-handle-class {
    z-index: 200;

    position: absolute;
    background-color: white; /*var(--v-primary-base);*/
    border: 2px solid white;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    opacity: 1;
    box-model: border-box;
    -webkit-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
  }

  .my-handle-class-ml {
    top: 50%;
    margin-top: -7px;
    left: -6px;
    cursor: w-resize;
  }

  .my-handle-class-tm {
    top: -6px;
    left: 50%;
    margin-left: -7px;
    cursor: n-resize;
  }

  .my-handle-class-mr {
    top: 50%;
    margin-top: -7px;
    right: -6px;
    cursor: e-resize;
  }

  .my-handle-class-bm {
    bottom: -6px;
    left: 50%;
    margin-left: -7px;
    cursor: s-resize;
  }

  .my-handle-class-tl {
    top: -5px;
    left: -5px;
    cursor: nw-resize;
  }

  .my-handle-class-tr {
    top: -5px;
    right: -5px;
    cursor: ne-resize;
  }

  .my-handle-class-bl {
    bottom: -5px;
    left: -5px;
    cursor: sw-resize;
  }

  .my-handle-class-br {
    bottom: -5px;
    right: -5px;
    cursor: se-resize;
  }

  .my-handle-class-tl:hover,
  .my-handle-class-tl:active,
  .my-handle-class-tr:hover,
  .my-handle-class-tr:active,
  .my-handle-class-bl:hover,
  .my-handle-class-bl:active,
  .my-handle-class-br:hover,
  .my-handle-class-br:active,
  .my-handle-class-tm:hover,
  .my-handle-class-tm:active,
  .my-handle-class-ml:hover,
  .my-handle-class-ml:active,
  .my-handle-class-mr:hover,
  .my-handle-class-mr:active,
  .my-handle-class-bm:hover,
  .my-handle-class-bm:active {
    opacity: 1;
    transform: scale(1.4);
  }

  .date-info-class {
    border-top-right-radius: 5px;
    opacity: 0.9;
    position: absolute;
    bottom: 0px;
    background-color: var(--v-primary-base);
    padding-left: 6px;
    padding-right: 6px;
    font-size: 13px;
  }
  .opacity-info-class {
    border-bottom-right-radius: 5px;
    opacity: 0.7;
    position: absolute;
    top: 0px;
    border-bottom: 1px solid black;
    background-color: var(--v-primary-base);
    border-right: 1px solid black;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 13px;
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
  }

  .nodrag {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  .loading-page {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

</style>
