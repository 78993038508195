<template>
  <SharePageTemplate>
    <template v-slot:image-content>
      <ImageCompareArea
        :sliderLineWidth="2"
        :leftImage="$route.query.start"
        :rightImage="$route.query.end"
        :switch="false"
      />
    </template>
  </SharePageTemplate>
</template>

<script>
import SharePageTemplate from '@/components/share/SharePageTemplate.vue';

import ImageCompareArea from '@components/image/ImageCompareArea.vue';

export default {
  name: 'SharedAreaCompareView',
  components: {SharePageTemplate, ImageCompareArea},
};
</script>
